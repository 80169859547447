import loginLogo from "../assets/images/login_logo.png"
import eyeIcon from "../assets/images/eye_icon.svg"
import eyeclose from "../assets/images/eye-crossed.svg"
import searchIcon from "../assets/images/search_icon.svg"
import profileIcon from "../assets/images/profile_icon.svg"
import notificationIcon from "../assets/images/notification_icon.svg"
import addIcon from "../assets/images/add_icon.svg"
import editIcon from "../assets/images/edit_icon.svg"
import deleteIcon from "../assets/images/delete_icon.svg"
import camera from "../assets/images/camera.svg"
import message from "../assets/images/message.svg"
import mail from "../assets/images/mail.svg"
import whatsapp from "../assets/images/whatsapp.svg"
import user_profile from "../assets/images/user-profile.svg"
import no_data_found from "../assets/images/404image.jpg"
import biometric from "../assets/images/biometric.svg"
import bulkUpload from "../assets/images/Bulkupload.png"
import add_btn_icon from "../assets/images/Add-btn-icon.svg"
import cross_btn from "../assets/images/cross-btn.svg"
import writeContent from "../assets/images/Write-Content.png"
import customerPic from "../assets/images/customer-profile.png"
import trainingDemo from "../assets/images/training-demo.jpg"
import dumble_icon from "../assets/images/dumble-icon.svg"
import clock_icon from "../assets/images/orange-clock.svg"
import message_icon from "../assets/images/message-icon.svg" 
import modal_cross from "../assets/images/modal-cross.png"
import import_icon from "../assets/images/import-icon.svg"
import manual_icon from "../assets/images/manual-icon.svg"
import consent from "../assets/images/exercise-consent.jpg"
import nextIcon from "../assets/images/next-btn.png"
import prevIcon from "../assets/images/prev-btn.png"
import crossed from "../assets/images/crossred.svg"
import whatsappIcon from "../assets/images/whatsappicon.svg"
import profileImage from "../assets/images/profile.png"
import addBranch from "../assets/images/addbrandh.png"
import phoneicon from '../assets/images/phone.svg'
import exerciseImage from "../assets/images/exercise.png"
import rope from "../assets/images/rope.png"
import assignProfile from "../assets/images/asign-profile.png"
import arrowRight from "../assets/images/arrow-right.svg"
import exerciseDetails from "../assets/images/exerciseDetail.png"
import add from "../assets/images/add.svg"
import remove from "../assets/images/remove.svg"
import addImg from "../assets/images/add_image.svg"
import call from "../assets/images/Union.svg"
import eye from "../assets/images/eye.svg"
import print from "../assets/images/print.svg"
import download from "../assets/images/download.svg"
import people from "../assets/images/people-icon.svg"
import staff from "../assets/images/staff-icon.svg"
import groupPeople from "../assets/images/user-profile-icon.svg"
import sale from "../assets/images/clipboard-text.svg"
import graph from "../assets/images/graph-img.png"
import graph_icon from "../assets/images/graph-icon.png"
import reminder from "../assets/images/reminder.svg"
import billing from "../assets/images/billing.svg"
import due_membership from "../assets/images/due.svg"
import threeDots from "../assets/images/3dots-icon.svg"
import pause from "../assets/images/pause.svg"
import left from "../assets/images/left.svg"
import dropDowns from "../assets/images/drop-downs.svg"
import up_icon from "../assets/images/up_icon.svg"
import calander from "../assets/images/calander.svg"
import calenderStaff from "../assets/images/calenderStaff.svg"
import clockStaff from "../assets/images/clockStaff.svg"
import editingIcon from "../assets/images/editingIcon.svg"
import checkbox from "../assets/images/checkbox.svg"
import vectorLeft from "../assets/images/vectorLeft.svg"
import vectorRight from "../assets/images/vectorRight.svg"
import downloadVector from "../assets/images/downloadVector.svg"
import checkOut from "../assets/images/checkout.svg"
import checkIn from "../assets/images/checkIn.svg"
import edit_black from "../assets/images/edit.svg"
import edit_green from "../assets/images/edit_green.svg"
import edit_sky from "../assets/images/edit_sky.svg"

export const LOGIN_LOGO = loginLogo;
export const EYE_ICON = eyeIcon;
export const EYE_CLOSE = eyeclose;
export const SEARCH_ICON = searchIcon;
export const PROFILE_ICON = profileIcon;
export const NOTIFICATION_ICON = notificationIcon;
export const ADD_ICON = addIcon;
export const EDIT_ICON = editIcon;
export const DELETE_ICON = deleteIcon;
export const MAIL = mail;
export const CAMERA = camera;
export const WHATSAPP = whatsapp;
export const MESSAGE = message;
export const USER_PROFILE = user_profile;
export const NO_DATA_FOUND = no_data_found;
export const BIOMETRIC = biometric;
export const BULK_UPLOAD = bulkUpload;
export const ADD_BTN_ICON = add_btn_icon;
export const CROSS_BTN = cross_btn;
export const WRITE_CONTENT = writeContent;
export const CUSTOMER_PIC = customerPic;
export const TRAINING_DEMO = trainingDemo;
export const DUMBLE_ICON = dumble_icon;
export const CLOCK_ICON = clock_icon;
export const MESSAGE_ICON = message_icon;
export const MODAL_CROSS = modal_cross;
export const IMPORT_ICON = import_icon;
export const MANUAL_ICON = manual_icon;
export const CONSENT = consent;
export const NEXT_ICON = nextIcon;
export const PREV_ICON = prevIcon;
export const CROSSED = crossed;
export const WHATSAPPICON = whatsappIcon;
export const PROFILEIMAGE = profileImage;
export const PHONEICONE = phoneicon;
export const ADDBRANCH = addBranch
export const EXCERCISEIMAGE = exerciseImage
export const ROPE = rope
export const ASSIGNPROFILE = assignProfile
export const ARROWRIGHT = arrowRight
export const EXCERCISEDETAIL = exerciseDetails
export const ADD = add
export const REMOVE = remove
export const ADD_IMG = addImg
export const CALL = call
export const EYE = eye
export const PRINT = print
export const DOWNLOAD = download
export const PEOPLE = people
export const STAFF = staff
export const GROUP_PEOPLE = groupPeople
export const SALE = sale
export const GRAPH = graph
export const GRAPH_ICON = graph_icon
export const REMINDER = reminder
export const BILLING = billing
export const DUE_MEMBERSHIP = due_membership
export const PAUSE = pause
export const LEFT = left
export const THREE_DOTS = threeDots
export const DROP_DOWNS = dropDowns
export const UP_ICON = up_icon
export const CALANDER = calander
export const CALENDER_STAFF = calenderStaff
export const CLOCK_STAFF = clockStaff
export const EDITINGICON = editingIcon
export const CHECKIN = checkIn
export const CHECKBOX = checkbox
export const VECTORLEFT = vectorLeft
export const VECTORRIGHT = vectorRight
export const DOWNLOADVECTOR = downloadVector
export const CHECKOUT = checkOut
export const EDIT_GREEN = edit_green
export const EDIT_BLACK = edit_black
export const EDIT_SKY = edit_sky




const ERR_MESSAGE = {
    EMPTY_TRANSACTIONID: "Enter transaction id",
    EMPTY_PLANPRICE: "Enter plan price",
    EMPTY_PLANPERIOD: "Enter month",
    EMPTY_PLANSTART: "Select start date",
    EMPTY_MEMBERSINCE: "Enter member since",
    EMPTY_MODEOFPAYMENT: "Select payment mode",
    EMAIL_EMPTY: "Enter email",
    EMPTY_ENDDATE: "Select end date",
    EMPTY_EXCERCISE: "Select exercise",
    EMPTY_SETS: "Enter sets",
    EMPTY_MOBILE: "Enter mobile number",
    EMPTY_BASEAMOUNT: "Enter base amount",
    EMPTY_REGISTRATION: "Enter registration Id",
    EMPTY_DAY: "Enter day",
    EMPTY_REASON: "Select freeze reason",
    EMPTY_DAY: "Select day",
    EMPTY_NETAMOUNT: "Enter net amount",
    EMPTY_PAIDAMOUNT: "Enter paid amount",
    INVALID_DATEOFBIRTH: "Enter valid date of birth",
    INVALID_ABOUT: "Character limit exceeded (max 600 characters)",
    INVALID_REMARK: "Character limit exceeded (max 300 characters)",
    INVALID_TITLE: "Enter valid workout name",
    EMPTY_IMAGE1: "Please upload image/video",
    EMPTY_EXCERCISENAME: "Enter exercise name",
    EMPTY_TIME: "Enter time",
    EMPTY_ACTIVITY: "Select activity level",
    INVALID_DURATION: "Enter valid duration",
    EMPTY_DURATION: "Enter duration",
    EMPTY_REPS: "Enter reps",
    INVALID_PLANPRICE: "Enter valid plan price",
    EMPTY_STATE: "Enter state",
    INVALID_REPS: "Enter valid reps",
    INVALID_SETS: "Enter valid sets",
    INVALID_TIME: "Enter valid time",
    EMPTY_CITY: "Enter city",
    INVALID_CALLSTATUS: "Enter valid call status",
    EMPTY_COUNTRY: "Enter country",
    EMPTY_ZIPCODE: "Enter zip code",
    EMPTY_ROLE: "Enter role",
    INVALID_EXCERCISENAME: "Enter valid exercise name",
    EMPTY_TITLE: "Enter workout title",
    EMPTY_BRANCH: "Enter branch",
    EMPTY_EXPERIENCE: "Enter experience",
    EMPTY_SPECIALIZATION: "Please add specialization",
    EMPTY_ABOUT: "Enter above field",
    INVALID_DATE: "Enter valid date",
    EMAIL_INVALID: "Enter valid email address",
    PASSWORD_EMPTY: "Enter password",
    EMPTY_SOURCE: "Select source",
    EMPTY_GENDER: "Select gender",
    EMPTY_LOCATION: "Select location",
    EMPTY_CALLSUBJECT: "Select call subject",
    EMPTY_CALLRESPONSE: "Select call response",
    EMPTY_REASON: "Select reason",
    EMPTY_PRIORITY: "Select priority",
    EMPTY_REMARK: "Enter remark",
    INVALID_MOBILE: "Enter valid  number",
    EMPTY_STAFF: "Select staff",
    EMPTY_CONVERSATION: "Select conversation",
    INVALID_PASSWORD: "Password must contain 8 or more characters including 1 uppercase letter, 1 lower case letter, 1 numeric and a special character",
    INVALID_PASSWORD_1: "Password must contain 8 or more characters",
    TERMS_AND_CONDITIONS: "Accept “Terms & conditions”",
    MOBILE_EMPTY: "Enter mobile number",
    INVALID_MOBILE: "Enter valid number",
    WHATSAPP_EMPTY: "Enter whatsapp number",
    ALTERATE_EMPTY: "Enter alterate number",
    INVALID_DATEOFBIRTH: "Enter valid date of birth",
    EMPTY_DATEOFBIRTH: "Enter date of birth",
    INVALID_MOBILE_MIN: "Minimum 10 digits are required",
    EMPTY_DATE: "Enter date",
    EMPTY_TIME: "Enter time",
    EMPTY_HEIGHT: "Enter height",
    EMPTY_WEIGHT: "Enter weight",
    EMPTY_ADDRESS: "Enter address",
    EMPTY_CALLSTATUS: "Enter call status",
    EMPTY_IMAGE: "Please upload image",
    EMPTY_FULLNAME: "Enter name",
    EMPTY_PROSPECTYPE: "Select prospect type",
    INVALID_FULLNAME_MIN: "Name should have minimum 3 characters",
    INVALID_FULLNAME_MAX: "Name should have maximum 30 characters",
    INVALID_FULLNAME: "Enter valid name",
    // NOTMATCHED: "Password and confirm password do not match",
    // EMPTY_OLD_PASSWORD: "Enter old password",
    // EMPTY_NEW_PASSWORD: "Enter new password",
    // EMPTY_CONFIRMPASSWORD: "Enter confirm password",
    BALANCE_AMOUNT: "Enter balance amount",
    EMPTY_TRAINER: "Select trainer",
    EMPTY_SHIFT: "Select shift",
    START_TIME: "Select start time",
    END_TIME: "Select end time",
    CHECK_IN:"Select Time",
    EMPTY_OPTION:"Select atleast one options",
    EMPTY_STAFF_TIME:"Select shift time"

};

export default ERR_MESSAGE;
import React, { useState, useEffect, useRef } from 'react'
import { DELETE_ICON, DUE_MEMBERSHIP, EDIT_ICON, GRAPH, GRAPH_ICON, GROUP_PEOPLE, PEOPLE, SALE, STAFF, USER_PROFILE, CROSS_BTN, PAUSE, LEFT, THREE_DOTS, SEARCH_ICON } from '../../../../Middleware/images'
import "../../../../assets/style/dashboard.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ERR_MESSAGE from '../../../../Helper/error-helper';
import DatePickerValue from '../../../../components/date-picker';
import Services from "../../../../Helper/services";
import DEVELOPMENT_CONFIG from '../../../../Helper/config';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
import TITLES from '../../../../Helper/title';
import ReactLoader from '../../../../Loader/react-loader';
import Pagination from '../../../../components/pagination';
import moment from 'moment';
import { IoFilter } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import Select from 'react-select';
import { format, differenceInDays, isToday, isTomorrow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../Middleware/routes';

const styleLead = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    height: 650,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    overflow: "hidden",
    overflowY: "scroll"
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    height: 530,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

const styleLeft = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 270,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

const expireOptions = [{ value: "last7days", label: 'Last 7 days' },
{ value: "yesterday", label: 'Yesterday' },
{ value: "today", label: 'Today' },
{ value: "tomorrow", label: 'Tommorrow' },

{ value: "upcoming7days", label: 'Upcomming 7 days' }]

//  const trainingOptions=[{ value: 1, label: 'GT' },{ value: 2, label: 'PT' }]


function Dashboard() {
    const [trainerList, setTrainerList] = useState([]);
    const [customerCount, setCustomerCount] = useState("")
    const [leadCount, setleadCount] = useState("")
    const [membershipCount, setMembershipCount] = useState("")
    const [staffCount, setStaffCount] = useState("")
    const [loading, setLoading] = useState(false);
    const [userChangedInfo, setUserChangedInfo] = useState(false);
    const [id, setId] = useState(null)
    const currentDate = new Date().toISOString().split('T')[0];
    const [errors, setErrors] = useState('');
    const pageSize = 10;
    const [userId, setUserId] = useState(null)
    const [openLead, setOpenLead] = useState(false);
    const [openFreeze, setOpenFreeze] = useState(false);
    const [openLeft, setOpenLeft] = useState(false);
    const [openPt, setOpenPt] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [customerList, setCustomerList] = useState([]);
    const [total, setTotal] = useState("")
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1)
    const endIndex = Math.min(startIndex + pageSize - 1, total)
    const totalPages = Math.ceil(total / pageSize)
    const [search, setSearch] = useState("")
    const [endDate, setEndDate] = useState(null)
    const [isFilter, setIsFilter] = useState({
        filterOn: false,
        filterApply: false
    })
    const [filterValue, setFilterValue] = useState({
        expire: "",
        // training:""
    })



    const navigate = useNavigate()
    const [data1, setData1] = useState({
        discount_type: "",
        discount: "",
        base_amount: "",
        net_amount: "",
        paid_amount: "",
        balance_amount: "",
        month: "",
        days: "0",
        start_date: currentDate,
        end_date: "",
        mode_of_payment: "",
        transaction_id: "",
        plan_type: "1",
        trainer_id: ""
    })

    const [freeze, setFreeze] = useState({
        reason: "",
        day: "",
        startFreeze: "",
        endFreeze: ""
    })

    const handleChangeFreeze = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setFreeze({
            ...freeze,
            [name]: value
        })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getApi(page);
    };

    const handleBlur = (e) => {
        setData1({
            ...data1,
            [e.target.name]: 0
        })
    }

    const handleOpenFreeze = (id) => {
        setOpenFreeze(true);
    };

    const handleCloseFreeze = () => {
        setErrors("")
        setOpenFreeze(false)
    };

    const handleOpenLeft = (id) => {
        setOpenLeft(true);
        setId(id)
    };

    const handleCloseLeft = () => {
        setOpenLeft(false)
    };

    const handleOpenPt = (id) => {
        setOpenPt(true);
        setId(id)
    };

    const handleClosePt = () => {
        setOpenPt(false)
    };
    async function getTrainerApi() {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-list`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setTrainerList(result?.body?.data);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }


    const handleOpenLead = (id, user_id, item) => {
        setId(id)
        setOpenLead(true);
        getMembershipDetail(id)
        setUserId(user_id)
        setEndDate(item.end_date)
    };



    const handleSearch = (value) => {

        setSearch(value)

    }
    const selectRef = useRef(null);

    const openfilterCard = () => {
        setIsFilter({
            ...isFilter,
            filterOn: !isFilter.filterOn
        })
    }
    useEffect(() => {
        if (isFilter.filterOn && selectRef.current) {
            selectRef.current.onMenuOpen();
        }
    }, [isFilter.filterOn]);

    const selectHandler = (date, type) => {
        if (type === "expire") {
            setFilterValue({
                ...filterValue,
                expire: date?.value
            })

        }
        // if (filterValue?.expire !== "") {
        setIsFilter({
            filterOn: false,
            filterApply: true
        })
        // }

    }

    const clearFilter = () => {
        setIsFilter({
            filterOn: false,
            filterApply: false
        })
        setFilterValue({
            ...filterValue,
            expire: ""
        })


    }



    const filterSubmitHandler = (e) => {
        e.preventDefault()
        if (filterValue?.expire !== "") {
            setIsFilter({
                filterOn: false,
                filterApply: true
            })
        }

    }

    const handleCloseLead = () => {
        setOpenLead(false)
        setData1({
            ...data1,
            discount_type: "",
            discount: "",
            base_amount: "",
            net_amount: "",
            paid_amount: "",
            balance_amount: "",
            month: "",
            days: "",
            start_date: "",
            end_date: "",
            mode_of_payment: "",
            transaction_id: ""
        })
    };

    async function getCountApi() {
        setLoading(true)
        let result = await Services.getService("admin/total-count");
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setCustomerCount(result?.body?.customersList)
            setStaffCount(result?.body?.staffList)
            setMembershipCount(result?.body?.dueAmount)
            setleadCount(result?.body?.leadList)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    function isActiveUser(givenDate) {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const dateToCompare = new Date(givenDate);
        dateToCompare.setHours(0, 0, 0, 0);
        return currentDate < dateToCompare;
    }

    async function getApi(pageNumber) {
        if (search == "") {
            setLoading(true)
        }
        let url = `admin/expiring-memberships?offset=${pageNumber}&limit=${10}`
        if (search !== "") {
            url = url + `&search=${search}`
        }

        if (filterValue.expire !== "") {
            url = url + `&expire=${filterValue.expire}`
        }

        let result = await Services.getService(url);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setCustomerList(result?.body?.data);
            setTotal(result?.body?.total)

            setLoading(false);
        }
        else {
            setLoading(false);
        }

    }

    useEffect(() => {
        getCountApi()
        getApi(currentPage)
        getTrainerApi()
    }, [currentPage, search, isFilter.filterApply])

    const handleChange2 = (e) => {
        setErrors('');
        const name = e.target.name;
        let value = e.target.value;
        if ((name === 'days' || name === 'month' || name === "base_amount" || name === 'net_amount' || name === 'paid_amount') && parseFloat(value) < 0) {
            value = 0;
        }
        if (name === 'month' && parseFloat(value) > 12) {
            value = 0;
        }
        if (name === 'discount') {
            const baseAmount = parseFloat(data1?.base_amount);
            value = Math.min(parseFloat(value), baseAmount);
        }
        if (name === 'net_amount') {
            const baseAmount = parseFloat(data1?.base_amount);
            value = Math.min(parseFloat(value), baseAmount);
        }
        if (name === 'paid_amount') {
            const netAmount = parseFloat(data1?.net_amount);
            value = Math.min(parseFloat(value), netAmount);
        }
        setData1((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
        if (name === 'discount_type' && value === '2') {
            setData1((prevInfo) => ({
                ...prevInfo,
                discount: '',
            }));
        }

        if (name === 'base_amount' || name === 'discount_type' || name === 'discount') {
            calculateNetAmount();
        }

        if (name === 'net_amount' || name === 'paid_amount') {
            calculateBalance();
        }
        if (name === 'discount_type' && value === '1') {
            if (name === 'discount' && value > 100) {
                setData1((prevInfo) => ({
                    ...prevInfo,
                    discount: 100,
                }));
            }
        }

        if (name === "month" || name === "days") {
            setUserChangedInfo(true);
        }
    };

    const calculateNetAmount = () => {
        const baseAmount = Number(data1?.base_amount) || 0;
        const discount = Number(data1?.discount) || 0;
        if (data1?.discount_type === '1') {
            const discountAmount = (baseAmount * discount) / 100;
            const netAmount = baseAmount - discountAmount;
            setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
        }
        else if (data1?.discount_type === '2') {
            const discountAmount = data1.discount
            const netAmount = baseAmount - discountAmount;
            setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
        }
        else {
            setData1((prevInfo) => ({ ...prevInfo, net_amount: data1?.base_amount, paid_amount: data1?.base_amount }));
        }
    };

    const calculateBalance = () => {
        const netAmount = Number(data1?.net_amount) || 0;
        const paid_amount = Number(data1?.paid_amount) || 0;
        const balance_amount = netAmount - paid_amount;
        setData1((prevInfo) => ({ ...prevInfo, balance_amount: balance_amount }));
    };

    const handleStart = (newDate) => {
        if (data1.month > 0 || data1.days > 0) {
            const startDate = new Date(newDate) || currentDate;
            const month = parseInt(data1?.month || 0);
            const days = parseInt(data1?.days || 0);
            const totalDays = month * 29 + days;
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + totalDays);
            const formattedEndDate = endDate.toISOString().split('T')[0];
            setData1((prevFields) => ({
                ...prevFields,
                start_date: newDate,
                end_date: formattedEndDate,
            }));
        }
        else {
            setData1((prevFields) => ({
                ...prevFields,
                start_date: newDate,
                end_date: "",
            }));
        }
    };

    useEffect(() => {
        calculateNetAmount();
    }, [data1?.base_amount, data1?.discount_type, data1?.discount]);

    useEffect(() => {
        calculateBalance();
    }, [data1?.net_amount, data1?.paid_amount, data1?.base_amount]);


    useEffect(() => {
        setData1((prevInfo) => ({ ...prevInfo, paid_amount: data1?.net_amount }));
    }, [data1?.net_amount]);

    useEffect(() => {
        if (userChangedInfo) {
            handleStart(new Date().toISOString().split('T')[0]);
            setUserChangedInfo(false);
        }
    }, [data1?.month, data1?.days, userChangedInfo]);


    useEffect(() => {
        setStartFreeze(new Date().toISOString().split('T')[0]);
    }, [freeze.day]);

    const setMemberSince = (newDate) => {
        setData1((prevFields) => ({
            ...prevFields,
            end_date: newDate,
        }));
    };

    const setStartFreeze = (newDate) => {
        setErrors("")
        if (freeze.day > 0) {
            const startDate = new Date(newDate) || currentDate;
            const days = parseInt(freeze.day || 0);
            const totalDays = days - 1;
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + totalDays);
            const formattedEndDate = endDate.toISOString().split('T')[0];
            setFreeze((prevFields) => ({
                ...prevFields,
                startFreeze: newDate,
                endFreeze: formattedEndDate
            }));
        }
        else {
            setFreeze((prevFields) => ({
                ...prevFields,
                startFreeze: newDate,
                endFreeze: ""
            }));
        }
    };

    const setEndFreeze = (newDate) => {
        setFreeze((prevFields) => ({
            ...prevFields,
            endFreeze: newDate,
        }));
    };

    async function getMembershipDetail(id) {
        setLoading(true)
        let result = await Services.getService(`admin/get-membership-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if (result?.body == null) {
                const updatedData = {
                    ...result.body,
                    days: 0,
                    balance_amount: 0,
                    start_date: new Date(),
                    trainer_id: result?.body?.trainer_name

                };
                setData1(updatedData);
            }
            else {
                const updatedData = {
                    ...result.body,
                    days: result.body.days || 0,
                    balance_amount: result.body.balance_amount || 0,
                    start_date: new Date()

                };
                setData1(updatedData);
            }
            setLoading(false)
        }
        else {
            setLoading(false);
        }
    }



    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { base_amount,
            month,
            paid_amount,
            net_amount,
            mode_of_payment,
            start_date,
            trainer_id
        } = data1
        if (data1.plan_type == '2') {
            if (!trainer_id || trainer_id == undefined) {
                formIsValid = false;
                errors["trainer_id"] = ERR_MESSAGE.EMPTY_TRAINER
            }
        }
        if (!base_amount || base_amount.length === 0) {
            formIsValid = false;
            errors["base_amount"] = ERR_MESSAGE.EMPTY_BASEAMOUNT;
        }
        if (!net_amount || net_amount.length === 0) {
            formIsValid = false;
            errors["net_amount"] = ERR_MESSAGE.EMPTY_NETAMOUNT;
        }
        if (!paid_amount || paid_amount.length === 0) {
            formIsValid = false;
            errors["paid_amount"] = ERR_MESSAGE.EMPTY_PAIDAMOUNT;
        }
        if (!month) {
            formIsValid = false;
            errors["month"] = ERR_MESSAGE.EMPTY_PLANPERIOD;
        }
        else {
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(endDate);

            if (startDateObj <= endDateObj) {
                formIsValid = false;
                errors["start_date"] = "Enter valid date";
            }
        }
        if (!mode_of_payment || mode_of_payment.length === 0) {
            formIsValid = false;
            errors["mode_of_payment"] = ERR_MESSAGE.EMPTY_MODEOFPAYMENT;
        }
        setErrors(errors);
        return formIsValid;
    }

    const handleValidationFreeze = () => {
        let errors = {};
        let formIsValid = true;
        const { reason, day
        } = freeze
        if (!reason) {
            formIsValid = false;
            errors["reason"] = ERR_MESSAGE.EMPTY_REASON;
        }
        if (!day) {
            formIsValid = false;
            errors["day"] = ERR_MESSAGE.EMPTY_DAY;
        }
        setErrors(errors);
        return formIsValid;
    }

    async function postFreezeApi(e) {
        e.preventDefault()
        if (handleValidationFreeze()) {
            let data = JSON.stringify({
                "plan_type": data1.plan_type ? data1.plan_type : "1",
                "user_id": userId,
                "discount_type": data1.discount_type,
                "discount": data1.discount,
                "base_amount": data1.base_amount,
                "net_amount": data1.net_amount,
                "paid_amount": data1.paid_amount,
                "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
                "month": data1.month,
                "days": data1.days ? data1.days : "0",
                "start_date": data1.start_date,
                "end_date": data1.end_date,
                "mode_of_payment": data1.mode_of_payment,
                "transaction_id": data1.transaction_id
            });
            setLoading(true);
            const result = await Services.postService(`admin/edit-membership-details?id=${id}`, data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleCloseLead()
                setData1({
                    ...data1,
                    discount_type: "",
                    discount: "",
                    base_amount: "",
                    net_amount: "",
                    paid_amount: "",
                    balance_amount: "",
                    month: "",
                    days: "",
                    start_date: "",
                    end_date: "",
                    mode_of_payment: "",
                    transaction_id: ""
                })
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    function formatMobileNumber(mobileNumber) {
        if (mobileNumber && mobileNumber.length >= 10) {
            const firstDigits = mobileNumber.slice(0, 1);
            const lastDigits = mobileNumber.slice(-3);
            const formattedNumber = `${firstDigits} xxxxxx ${lastDigits}`;
            return formattedNumber;
        }
        return mobileNumber;
    }

    const role_id = localStorage.getItem("role_id")

    async function postMembershipApi(e) {
        e.preventDefault()
        if (handleValidation()) {
            let data = JSON.stringify({
                "plan_type": data1.plan_type ? data1.plan_type : "1",
                "user_id": userId,
                "discount_type": data1.discount_type,
                "discount": data1.discount,
                "base_amount": data1.base_amount,
                "net_amount": data1.net_amount,
                "paid_amount": data1.paid_amount,
                "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
                "month": data1.month,
                "days": data1.days ? data1.days : "0",
                "start_date": data1.start_date,
                "end_date": data1.end_date,
                "mode_of_payment": data1.mode_of_payment,
                "transaction_id": data1.transaction_id,
                "trainer_id": data1.trainer_id

            });
            setLoading(true);
            const result = await Services.postService("admin/purchase-membership", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleCloseLead()
                setData1({
                    ...data1,
                    discount_type: "",
                    discount: "",
                    base_amount: "",
                    net_amount: "",
                    paid_amount: "",
                    balance_amount: "",
                    month: "",
                    days: "",
                    start_date: "",
                    end_date: "",
                    mode_of_payment: "",
                    transaction_id: ""
                })
                getApi(currentPage);

            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    async function getGymLeftAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/gym-left?customer_id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleCloseLeft();
            getApi(currentPage)
            toast.success(result.message)
        } else {
            setLoading(false);
            toast.error(result.message)
        }
    }

    const getUserStatus = (endDate) => {
        const currentDate = new Date();
        const endDateParsed = new Date(endDate);
        const daysLeft = differenceInDays(endDateParsed, currentDate);
        const daysSinceExpiration = differenceInDays(currentDate, endDateParsed);

        if (daysLeft > 7) {
            return { status: 'Active', message: 'Active' };
        } else if (isToday(endDateParsed)) {
            return { status: 'Expired', message: 'Expires Today' };
        } else if (isTomorrow(endDateParsed)) {
            return { status: 'Expired', message: 'Expires Tomorrow' };
        } else if (daysLeft > 0 && daysLeft <= 7) {
            return { status: 'Warning', message: `Expires on ${format(endDateParsed, 'd MMM')}` };
        } else if (daysSinceExpiration <= 7 && daysSinceExpiration > 0) {
            return { status: 'Expired', message: `Expired on ${format(endDateParsed, 'd MMM')}` };  // Shows day and month
        } else {
            return { status: 'Expired', message: 'Expired' };
        }
    };


    return (
        <>
            <Helmet>
                <title>{TITLES.DASHBOARD}</title>
            </Helmet>
            {(loading && search == "") && <ReactLoader />}
            <section className="main-spacing mt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4 col-lg-3 col-xxl">
                            <div className='dash-card py-4 px-3 d-flex gap-3 mb-3 mb-xxl-0' onClick={() => {
                                navigate(ROUTES.CUSTOMERS)
                            }} style={{ cursor: 'pointer' }}>
                                <div className="dash-img d-flex justify-content-center align-items-center">
                                    <img src={GROUP_PEOPLE} />
                                </div>
                                <div className="dash-title">
                                    <p className='dash-head fw-500 f16-size'>Total Customers</p>
                                    <p className='dash-text fw-600'>{customerCount}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 col-lg-3 col-xxl" onClick={() => {
                            navigate(ROUTES.STAFF)
                        }} style={{ cursor: 'pointer' }}>
                            <div className='dash-card py-4 px-3 d-flex gap-3 mb-3 mb-xxl-0'>
                                <div className="dash-img d-flex justify-content-center align-items-center">
                                    <img src={PEOPLE} />
                                </div>
                                <div className="dash-title">
                                    <p className='dash-head fw-500 f16-size'>Staff</p>
                                    <p className='dash-text fw-600'>{staffCount}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 col-lg-3 col-xxl" style={{ cursor: 'pointer' }} onClick={() => {
                            navigate(ROUTES.DASHBOARD)
                        }} >
                            <div className='dash-card py-4 px-3 d-flex gap-3 mb-3 mb-xxl-0'>
                                <div className="dash-img d-flex justify-content-center align-items-center">
                                    <img src={DUE_MEMBERSHIP} />
                                </div>
                                <div className="dash-title">
                                    <p className='dash-head fw-500 f16-size'>Due Membership</p>
                                    <p className='dash-text fw-600'>{membershipCount}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 col-lg-3 col-xxl" onClick={() => {
                            navigate(ROUTES.LEADES)
                        }} style={{ cursor: 'pointer' }}>
                            <div className='dash-card py-4 px-3 d-flex gap-3 mb-3 mb-xxl-0'>
                                <div className="dash-img d-flex justify-content-center align-items-center">
                                    <img src={STAFF} />
                                </div>
                                <div className="dash-title">
                                    <p className='dash-head fw-500 f16-size'>News Leads</p>
                                    <p className='dash-text fw-600'>{leadCount}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 col-lg-3 col-xxl">
                            <div className='dash-card py-4 px-3 d-flex gap-3 mb-3 mb-xxl-0'>
                                <div className="dash-img d-flex justify-content-center align-items-center">
                                    <img src={SALE} />
                                </div>
                                <div className="dash-title">
                                    <p className='dash-head fw-500 f16-size'>Total Sale Target</p>
                                    <p className='dash-text fw-600'>40%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-spacing mt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 leads-table">
                            <div className="row pb-2">
                                <div className="col-12">
                                    <div className="leads-main d-block d-sm-flex gap-3 align-items-center justify-content-between mb-2 dashboard-search">
                                        <div className="   leads-head  title-heading-container ">
                                            <div className='d-flex gap-3 - align-items-center title-heading'><h3 className='dash-upgrade'
                                            >Upgrade Membership</h3>
                                                <div className="search-category position-relative dash-search-container ">
                                                    <form className="d-flex" >
                                                        <input className="form-control ms-0 ms-sm-2 nav-input-search cus-nav-input-search   " type="search" placeholder="Search Customers..."
                                                            value={search}
                                                            onChange={(e) => {

                                                                handleSearch(e.target.value)
                                                            }}
                                                        />
                                                        <img src={SEARCH_ICON} className="search-icon custom-search-icon" />
                                                    </form>
                                                </div>


                                            </div>


                                        </div>
                                        <div className='filter-container relative'>


                                            {isFilter?.filterApply ? <div className='d-flex gap-3 mr-2  align-items-center  ' onClick={() => clearFilter()}>
                                                <h3 className='filters-text'>CLEAR FILTER</h3>
                                                <RxCross2 className='filter-icon' />
                                            </div> : <div className='d-flex gap-3 mr-2  align-items-center' onClick={() => openfilterCard()}>
                                                <h3 className='filters-text'>FILTERS</h3>
                                                <IoFilter className='filter-icon' />
                                            </div>}
                                            <div className={`custom-filter-dropdown shadow ${isFilter?.filterOn ? "d-block" : " d-none"} `}>

                                                <form className='d-flex gap-3 flex-column '

                                                // onSubmit={filterSubmitHandler}
                                                >

                                                    <div className='d-flex gap-5 justify-content-between slect-container'>
                                                        <Select
                                                            ref={selectRef}
                                                            isSearchable={false}
                                                            onChange={(value) => selectHandler(value, "expire")}
                                                            options={expireOptions}
                                                            className='custom-select'
                                                            placeholder="Select "


                                                        />

                                                    </div>
                                                    {/* <div className='d-flex gap-5 justify-content-between'>
                                                    <label>TRANINGTYPE</label>
                                                    <Select
                                                    isSearchable={false}
                                                     onChange={(value)=>selectHandler(value,"trainingType")}
                                                    options={trainingOptions}
                                                    className='custom-select'
                                                    
                                                    />

                                                </div> */}
                                                    {/* <div className='d-flex justify-content-end'>
                                                        <button type="submit" className=" leads-btn  filter-btn">Apply</button>
                                                    </div> */}

                                                </form>
                                            </div>

                                        </div>

                                        {/* <div className="leads-drop-downs d-flex  justify-content-start justify-content-sm-center gap-3">
                                            <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">All Customers</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>

                                            <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive leads-table-responsive">
                                {
                                    customerList?.length == 0 ? <div className='notFound'>    All memberships are currently up-to-date.
                                    </div> : <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="leads-table-head">
                                                    SERIAL No
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    Name
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    Mobile No.
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    Training Type
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    Start Date
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    end date
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    status
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    Membership
                                                </th>
                                                <th scope="col" className="leads-table-head">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customerList?.map((item, index) => (
                                                <tr key={item.id}>

                                                    <td className="leads-table-data text-start">
                                                        {index + 1}
                                                    </td>
                                                    <td className="leads-table-data">
                                                        {item?.owner_name}
                                                    </td>
                                                    <td className="leads-table-data">
                                                        {role_id === "1" || role_id === "2" ? item.mobile_number : formatMobileNumber(item.mobile_number)}                                      </td>
                                                    <td className="leads-table-data">
                                                        {item?.plan_type == "1" ? "GT" : "PT"}
                                                    </td>
                                                    <td className="leads-table-data">
                                                        {moment(item.start_date).format('Do MMM, YYYY')}
                                                    </td>
                                                    <td className="leads-table-data">                                                {moment(item.end_date).format('Do MMM, YYYY')}
                                                    </td>
                                                    <td className="leads-table-data">
                                                        <p className="">
                                                            {/* {
                                                                item.status == 1 || item.status == 0 ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>
                                                            } */}

                                                            {/* <span> {item?.status == "0" ? "Upcoming Membership" : item?.status == "1" ? "Active" : item?.status == "2" ? "Expired" : item?.status == "3" ? "Freezed " : "Gym Left"}</span> */}
                                                            {item.status == 'Active' ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}
                                                            {<span>{item.status}</span>}


                                                        </p>                        </td>
                                                    <td
                                                        onClick={() => handleOpenLead(item.id, item.user_id, item)}
                                                    ><span className="upgrade-text" style={{ cursor: "pointer" }}>Upgrade Membership</span></td>
                                                    <td>
                                                        {/* {
                                                            item.plan_type == "2" ? <span className="close-text" onClick={() => handleOpenPt()} style={{ cursor: "pointer" }}>PT Close</span> : */}
                                                        <div className="dropdown">
                                                            <a
                                                                type="button"
                                                                id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <img
                                                                    src={THREE_DOTS}
                                                                    alt="3dots"
                                                                />
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu"
                                                                style={{ zIndex: "1000" }}
                                                                aria-labelledby="dropdownMenuButton1 "
                                                            >
                                                                <li style={{ cursor: "pointer" }}>
                                                                    {/* <a
                                                                            className="dropdown-item "
                                                                            onClick={() =>
                                                                                handleOpenFreeze(item.id)
                                                                            }
                                                                        >
                                                                            Freeze membership
                                                                        </a> */}
                                                                </li>
                                                                <li style={{ cursor: "pointer" }}>
                                                                    <a
                                                                        className="dropdown-item"
                                                                        onClick={() =>
                                                                            handleOpenLeft(item.user_id)
                                                                        }
                                                                    >
                                                                        Gym left
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {/* } */}

                                                    </td>
                                                </tr>
                                            ))
                                            }

                                        </tbody>
                                    </table>
                                }

                            </div>
                            {
                                customerList?.length > 0 && <>
                                    <div className="row pt-3">
                                        <div className="col-6">
                                            <a href=""><span className="page-entry">
                                                {
                                                    total === 1 ? <>
                                                        Showing {total} entry

                                                    </> :
                                                        <>
                                                            Showing {startIndex} to {endIndex} of {total} entries

                                                        </>
                                                }
                                            </span>
                                            </a>
                                        </div>
                                        <div className="col-6">
                                            <nav aria-label="Page">
                                                <ul className="pagination d-flex justify-content-end">
                                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="main-spacing my-3">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 mb-3">
                            <div className="row">
                                <div className="col-12 leads-table">
                                    <div className="row pb-2">
                                        <div className="col-12">
                                            <div className="leads-main d-block d-sm-flex align-items-center justify-content-between mb-2">
                                                <div className="leads-head">
                                                    <h3 className='dash-upgrade'>Reminder</h3>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive responsive-dashboard">
                                        {
                                            customerList?.length == 0 ? <div className='notFound'>    All memberships are currently up-to-date.
                                            </div> : <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="leads-table-head">
                                                            Name
                                                        </th>
                                                        <th scope="col" className="leads-table-head">
                                                            Status
                                                        </th>
                                                        <th scope="col" className="leads-table-head">
                                                            Membership
                                                        </th>
                                                        <th scope="col" className="leads-table-head">
                                                            Action                                </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {customerList?.map((item, index) => (
                                                        <tr key={item.id}>

                                                            <th scope="row" className="leads-data-head d-flex">
                                                                <span className='customer-pic-head'>
                                                                    <img src={item?.profile_pic ? item?.profile_pic : USER_PROFILE} className='customer-pic me-2' alt='' />
                                                                </span>
                                                                <span>
                                                                    {item?.owner_name}
                                                                    <span className='d-block'>
                                                                        {role_id === "1" || role_id === "2" ? item.mobile_number : formatMobileNumber(item.mobile_number)}
                                                                    </span>
                                                                </span>
                                                            </th>
                                                            <td className="leads-table-data">
                                                                sent
                                                            </td>


                                                            <td className="leads-table-data">
                                                                <p className="">
                                                                    {/* {
                                                                item.status == 1 || item.status == 0 ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>
                                                            } */}

                                                                    {/* <span> {item?.status == "0" ? "Upcoming Membership" : item?.status == "1" ? "Active" : item?.status == "2" ? "Expired" : item?.status == "3" ? "Freezed " : "Gym Left"}</span> */}
                                                                    {item.status == 'Active' ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}
                                                                    {<span>{item.status}</span>}


                                                                </p>                        </td>
                                                            <td><span className="upgrade-white" style={{ cursor: "pointer" }}>Send</span></td>
                                                        </tr>
                                                    ))
                                                    }

                                                </tbody>
                                            </table>
                                        }

                                    </div>
                                    {
                                        customerList?.length > 0 && <>
                                            <div className="row pt-3">
                                                <div className="col-6">
                                                    <a href=""><span className="page-entry">
                                                        {
                                                            total === 1 ? <>
                                                                Showing {total} entry

                                                            </> :
                                                                <>
                                                                    Showing {startIndex} to {endIndex} of {total} entries

                                                                </>
                                                        }
                                                    </span>
                                                    </a>
                                                </div>
                                                <div className="col-6">
                                                    <nav aria-label="Page">
                                                        <ul className="pagination d-flex justify-content-end">
                                                            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className='dash-card py-4 px-3 d-flex gap-5 mb-3'>
                                <div className="dash-title">
                                    <p className='graph-customer fw-600 f14-size'>New Customers</p>
                                    <p className='dash-text fw-700'>200</p>
                                    <p className='graph-percentage fw-600 f-14-size'>+10%</p>
                                </div>
                                <div className="">
                                    <img src={GRAPH_ICON} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className='dash-card py-4 px-3 d-flex gap-5 mb-3'>
                                <div className="dash-title">
                                    <p className='graph-customer fw-600 f14-size'>Earnings</p>
                                    <p className='dash-text fw-700'>$2,80,00</p>
                                    <p className='graph-percentage fw-600 f-14-size'>+20%</p>
                                </div>
                                <div className="">
                                    <img src={GRAPH_ICON} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className='dash-card py-4 px-3 d-flex gap-5 mb-3 mb-xxl-0'>
                                <div className="dash-title">
                                    <p className='graph-customer fw-600 f14-size'>New Customers</p>
                                    <p className='dash-text fw-700'>200</p>
                                    <p className='graph-percentage fw-600 f-14-size'>+10%</p>
                                </div>
                                <div className="">
                                    <img src={GRAPH_ICON} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className='dash-card py-4 px-3 d-flex gap-5 mb-3 mb-xxl-0'>
                                <div className="dash-title">
                                    <p className='graph-customer fw-600 f14-size'>Earnings</p>
                                    <p className='dash-text fw-700'>$2,80,00</p>
                                    <p className='graph-percentage fw-600 f-14-size'>+20%</p>
                                </div>
                                <div className="">
                                    <img src={GRAPH_ICON} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                                <div className="col-12 my-3">
                                    <div className="total-review dash-card px-3 py-4">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <p className='fw-600 f18-size dash-common-text'>Total Revenue</p>
                                            <div>
                                                <button type="button" className="btn leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">2023</button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="graph-img text-center">
                                            <img src={GRAPH} alt="graph-img" className='img-fluid' />
                                        </div>
                                        <div className="graph-label d-flex justify-content-end align-items-center gap-4 mt-3">
                                            <div className="graph-dot-head d-flex align-items-center gap-1">
                                                <span className='graph-orange-dot'></span>
                                                <p className='graph-label-text fw-600'>Total Income</p>
                                            </div>
                                            <div className="graph-dot-head d-flex align-items-center gap-1">
                                                <span className='graph-orange-dot'></span>
                                                <p className='graph-label-text fw-600'>Total Outcome</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                </div>
            </section>

            <Modal
                open={openLead}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleLead} className="modal-head">
                    <div
                        onClick={handleCloseLead}
                        className=" position-relative mb-0 text-end pt-1 pe-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2 className='modalHeading'>Upgrade Membership</h2>
                    </div>

                    <form className='create-field purchase-create-field'>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Training type </label>
                                    <select name="plan_type" className="form-select common-input" value={data1?.plan_type} onChange={handleChange2} >
                                        <option value="1">General training</option>
                                        <option value="2">Personal training</option>
                                    </select>
                                </div>
                            </div>
                            {
                                data1?.plan_type == 2 && <div className="col-md-12">
                                    <div className="mb-2 mb-xxl-3">
                                        <label className="form-label mb-0">Trainer </label>

                                        <select name='trainer_id' value={data1.trainer_id} onChange={handleChange2} className="form-select common-input" aria-label="Default select example" >
                                            <option value="">Select Trainer</option>
                                            {
                                                trainerList?.map((item) => {
                                                    return (
                                                        <>
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        </>
                                                    )
                                                })
                                            }

                                        </select>
                                        {errors.trainer_id && <span className="err_msg">{errors.trainer_id}</span>}
                                    </div>
                                </div>
                            }

                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Base Amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            name="base_amount"
                                            value={data1?.base_amount?.slice(0, 15)}
                                            onChange={handleChange2}
                                            className="form-control common-input dollarInput"
                                            placeholder="Enter Price"
                                        />
                                    </div>
                                    {errors.base_amount && <span className="err_msg">{errors.base_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">DISCOUNT type </label>
                                    <select name="discount_type" className="form-select common-input" value={data1?.discount_type} onChange={handleChange2} >
                                        <option value="">Select</option>
                                        <option value="1">%(Percentage)</option>
                                        <option value="2">Lump sum</option>
                                    </select>
                                    {errors.discount_type && <span className="err_msg">{errors.discount_type}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">DISCOUNT</label><input type="number" className="form-control common-input" placeholder="Enter Discount" value={data1?.discount} onChange={handleChange2} name="discount" disabled={!data1?.discount_type} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Net amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number"
                                            name="net_amount"
                                            value={data1?.net_amount}
                                            onChange={handleChange2}
                                            readOnly={data1?.discount_type == "1" || !data1?.discount_type || data1.discount_type == "2"} className="form-control common-input dollarInput"
                                            placeholder="Enter Price"
                                        />
                                    </div>
                                    {errors.net_amount && <span className="err_msg">{errors.net_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">PAID amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number" placeholder="Enter paid amount" value={data1?.paid_amount} onChange={handleChange2} name="paid_amount"
                                            className="form-control common-input dollarInput"
                                        />
                                    </div>
                                    {errors.paid_amount && <span className="err_msg">{errors.paid_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Balance amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number" placeholder="Enter balance amount" value={data1?.balance_amount}

                                            name="balance_amount"
                                            className="form-control common-input dollarInput"
                                            onChange={(e) => {
                                                handleChange2(e);

                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">MONTH</label><input type="number" className="form-control common-input" placeholder="Enter Month" value={data1?.month} onChange={handleChange2} name="month" />
                                    {errors.month && <span className="err_msg">{errors.month}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Days</label><input type="number" onBlur={handleBlur} className="form-control common-input" placeholder="Enter days" value={data1?.days}
                                    onChange={handleChange2} name="days" />

                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">START date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.start_date ? data1.start_date : currentDate}
                                        setFn={handleStart}
                                    />
                                    {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.end_date}
                                        setFn={setMemberSince}
                                        endDate={data1?.end_date}
                                        name="planEnd"
                                    />
                                    {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">PAYMENT MODE</label>
                                    <select name="mode_of_payment" className="form-select common-input" value={data1?.mode_of_payment} onChange={handleChange2} >
                                        <option selected>Select</option>
                                        <option value="cash">Cash</option>
                                        <option value="gpay">Gpay</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.mode_of_payment && <span className="err_msg">{errors.mode_of_payment}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">TRANSACTION ID</label><input type="text" value={data1?.transaction_id} onChange={handleChange2} name="transaction_id" className="form-control common-input" placeholder="Enter ID" />
                                    {errors.transaction_id && <span className="err_msg">{errors.transaction_id}</span>}
                                </div>

                            </div>
                        </div>


                    </form>
                    <div className="mt-4 mb-3 text-center position-relative submit-head">
                        <button onClick={postMembershipApi}
                            type="submit"
                            className="common-btnn w-100"
                        >
                            Submit
                        </button>
                    </div>
                </Box>

            </Modal>
            <Modal
                open={openFreeze}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleCloseFreeze}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Freeze Membership</h2>
                    </div>
                    <form className='create-field'>
                        <div className='row'>

                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Freeze Days</label><input type="number" className="form-control common-input" placeholder="Enter Days" name="day" value={freeze.day} onChange={handleChangeFreeze} />
                                    {errors.day && <span className="err_msg">{errors.day}</span>}
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">START date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={freeze?.startFreeze ? freeze.startFreeze : currentDate}
                                        setFn={setStartFreeze}
                                    />
                                    {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={freeze?.endFreeze}
                                        setFn={setEndFreeze}
                                        endFreezeDate={freeze?.endFreeze}
                                        name="planEndFreeze"
                                    />
                                    {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Freeze Reason </label>
                                    <textarea name="reason" value={freeze.reason} onChange={handleChangeFreeze} rows="3" cols="50" className="form-control common-input" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div
                        className="mb-3 text-center position-relative submit-head"
                    // onClick={postFreezeApi}
                    >
                        <button
                            type="submit"
                            className="btn delete-btn"
                        >
                            Submit{" "}
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openLeft}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleLeft} className="modal-head">
                    <div
                        onClick={handleCloseLeft}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Gym Left</h2>
                    </div>
                    <div className="mb-4 position-relative">
                        <p>
                            Are you sure that you want to proceed with removing the customer from the platform? The customer has left the gym.
                        </p>
                    </div>
                    <div

                        onClick={getGymLeftAPI}
                        className="mb-3 text-center position-relative submit-head"
                    >
                        <button
                            type="submit"
                            className="btn delete-btn"
                        >
                            remove{" "}
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openPt}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleLeft} className="modal-head">
                    <div
                        onClick={handleClosePt}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>PT Close</h2>
                    </div>
                    <div className="mb-4 position-relative">
                        <p>
                            Are you sure that you want to proceed with closing the customer's Personal training.
                        </p>
                    </div>
                    <div
                        onClick={getGymLeftAPI}
                        className="mb-3 text-center position-relative submit-head"
                    >
                        <button
                            type="submit"
                            className="btn delete-btn"
                        >
                            close{" "}
                        </button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </>

    )
}

export default Dashboard
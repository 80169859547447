import React, { Children, useEffect, useState } from 'react'
import { BIOMETRIC, USER_PROFILE } from '../../../Middleware/images';
import "../../../assets/style/addCustomers.css";
import { useNavigate } from 'react-router-dom';
import ReactLoader from '../../../Loader/react-loader';
import ROUTES from '../../../Middleware/routes';
import ERR_MESSAGE from '../../../Helper/error-helper';
import uploadFileToS3 from "../../../Helper/fileUpload"
import { Helmet } from 'react-helmet';
import TITLES from '../../../Helper/title';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePickerValue from '../../../components/date-picker';

const AddCustomers = () => {
  const navigate = useNavigate();
  const [customerList, setCustomerList] = useState([]);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [info, setInfo] = useState({
    name: "",
    whatsapp_no: "",
    mobile_no: "",
    alternate_no: "",
    date_of_birth: "",
    email: "",
    refer: "",
    height: "",
    weight: "",
    gender: "",
    address: "",
    activity_level: "",
    // training_type: "",
    goal: "",
    sales_person: "",
  });

  const setDateOfBirth = (newDate) => {
    setInfo((prevFields) => ({
      ...prevFields,
      date_of_birth: newDate,
    }));
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const emojiSymbolRegex = /[^\p{L}\s]/u;
    const whatsappRegex = /^\d+$/;
    const { name, whatsapp_no, mobile_no, alternate_no, date_of_birth, email
    } = info
    if (!name || name?.trim().length === 0) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
    } else if (name.length < 3) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
    }
    else if (name.length > 30) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
    }
    else {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(name) || emojiSymbolRegex.test(name)) {
        formIsValid = false;
        errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
      }
    }
    if (!mobile_no || mobile_no?.trim().length === 0) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.EMPTY_MOBILE
    }
    else if (!whatsappRegex.test(mobile_no)) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (mobile_no.length < 10) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }
    if (!whatsapp_no || whatsapp_no?.trim().length === 0) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.WHATSAPP_EMPTY
    }
    else if (!whatsappRegex.test(whatsapp_no)) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (whatsapp_no.length < 10) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }

    if (!whatsappRegex.test(alternate_no) && alternate_no?.length >= 1) {
      formIsValid = false;
      errors["alternate_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (alternate_no.length < 10 && alternate_no?.length >= 1) {
      formIsValid = false;
      errors["alternate_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const parts = date_of_birth.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);
    const dob = new Date(year, month, day);
    dob.setHours(0, 0, 0, 0);

    if (dob.getTime() >= today.getTime()) {
      formIsValid = false;
      errors["date_of_birth"] = ERR_MESSAGE.INVALID_DATEOFBIRTH;
    }
    if (reg.test(email.toLowerCase()) === false && email.length > 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
    }
    setErrors(errors);
    return formIsValid;
  }
  const handleChange = (e) => {
    setErrors("")
    const { name, value } = e.target;
    const regex = /^[0-9]*$/;
    if (name === 'mobile_no' && !regex.test(value)) {
      return;
    }
    if (name === 'whatsapp_no' && !regex.test(value)) {
      return;
    }
    if (name === 'alternate_no' && !regex.test(value)) {
      return;
    }
    if (name === 'mobile_no') {
      setInfo({
        ...info,
        [name]: value,
        whatsapp_no: value
      });
    } else {
      setInfo({
        ...info,
        [name]: value
      });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const formattedValue = parseFloat(value).toFixed(2);

    setInfo({
      ...info,
      [name]: formattedValue,
    });
  };

  const fileChange = (e) => {
    setErrors("");
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        const formData = new FormData();
        formData.append("image", file);
        const imageURL = URL.createObjectURL(file);
        setImageUrl(imageURL);
        fileUpload(file)
      }
    }
  };

  const fileUpload = async (file) => {
    try {
      if (file) {
        const fileUrl = await uploadFileToS3(file);
        setFileUrl(fileUrl)
      }
    }
    catch (error) {
    }
  };



  async function postApi() {
    let data = {
      "company_id": localStorage.getItem("company_id"),
      "branch_id": localStorage.getItem("branch_id"),
      "profile_pic": fileUrl,
      "name": info?.name,
      "whatsapp_no": info.whatsapp_no,
      "alternate_no": info.alternate_no,
      "date_of_birth": info.date_of_birth,
      "email": info?.email,
      "address_1": info?.address,
      "registration_id": info?.registration_id,
      "activity_level": info?.activity_level,
      "prospect_source": info.prospect_source,
      "goal": info.goal,
      "sales_person": info.sales_person,
      "trainer_id": "1",
      "mobile_number": info.mobile_no,
      "biometric_id": ""
    };

    if (info.gender) {
      data.gender = info.gender;
    }
    if (info.height) {
      data.height = info.height;
    }
    if (info.weight) {
      data.weight = info.weight;
    }
    if (info.refer) {
      data.refer_by = info.refer;
    }

    setLoading(true);
    const result = await Services.postService("admin/add-customer", JSON.stringify(data));
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      localStorage.setItem("message", result.message);
      navigate(ROUTES.CUSTOMERS);
    }
    else {
      toast.error(result.message);
      setLoading(false);
    }
  }


  async function getApi() {
    setLoading(true)
    let result = await Services.getService(`admin/get-customers?company_id=${localStorage.getItem("company_id")}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setCustomerList(result?.body?.data);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getApi()
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault()
    if (handleValidation()) {
      postApi()
    }
  };

  return (
    <div>
      <Helmet>
        <title>{TITLES.ADD_CUSTOMER}</title>
      </Helmet>
      {loading && <ReactLoader />}
      <section className="add-customer common-bg form-height my-3 mx-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="add-customer-head mb-2 mb-lg-4">
                <h2 className="add-customer-text fw-600">Add Customer</h2>
              </div>
            </div>
            <div className="col-12">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item custom-accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button fw-600 custom-accordion-btn" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      BASIC DETAIL
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <form>
                        <div className="row">
                          <div className="col-12 col-lg-2">
                            <div className=" position-relative">
                              <div className={`profile-pic  ${imageUrl !== "" ? 'profile-pic-change' : ''}`}>
                                <div className={imageUrl === "" ? "profile-upper-pic" : ""}>
                                  <img src={imageUrl ? imageUrl : USER_PROFILE} className={`img-fluid  ${imageUrl !== "" ? 'img-preview ' : ""}`} id="blah" />
                                  <p className="f14-size text-black fw-600">{imageUrl ? "" : "Upload Image"}</p>
                                </div>
                              </div>
                              <input type="file" className="input-profile" id="image" accept=".jpg, .jpeg, .png" onChange={fileChange} />
                              {errors.imageUrl && <span className="err_msg">{errors.imageUrl}</span>}
                            </div>
                          </div>
                          <div className="col-12 col-lg-10">
                            <div className="row">
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">name</label>
                                  <input type="text" name='name' value={info.name} onChange={handleChange} className="form-control common-input" placeholder="Enter Name" />
                                  {errors.name && <span className="err_msg">{errors.name}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">Mobile No</label>
                                  <input type="number" name='mobile_no' value={info.mobile_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Whatsapp PHone No" />
                                  {errors.mobile_no && <span className="err_msg">{errors.mobile_no}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">Whatsapp No</label>
                                  <input type="number" name='whatsapp_no' value={info.whatsapp_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Whatsapp PHone No" />
                                  {errors.whatsapp_no && <span className="err_msg">{errors.whatsapp_no}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">ALternate No</label>
                                  <input type="number" name='alternate_no' value={info.alternate_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter ALternate No" />
                                  {errors.alternate_no && <span className="err_msg">{errors.alternate_no}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">DOB</label>

                                  <DatePickerValue
                                    setErrors={setErrors}
                                    value={info.date_of_birth}
                                    setFn={setDateOfBirth}
                                  />
                                  {errors.date_of_birth && <span className="err_msg">{errors.date_of_birth}</span>}
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                <div className="mb-3">
                                  <label className="form-label">Refer By</label>
                                  <select name='refer' value={info.refer} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                    <option value="">Select Customer</option>
                                    {
                                      customerList?.map((item) => {
                                        return (
                                          <>
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                          </>
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="accordion-item custom-accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button fw-600 custom-accordion-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      OTHER DETAIL
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                      <form>
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Height (CM)</label>
                                  <input type="number" name='height' value={info.height} onChange={handleChange} onBlur={handleBlur} className="form-control common-input" placeholder="Enter Height" />

                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Weight(kg)</label>
                                  <input type="number" name='weight' value={info.weight} onBlur={handleBlur}
                                    onChange={handleChange} className="form-control common-input" placeholder="Enter Weight" />
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Email</label>
                                  <input type="text" name='email' value={info.email} onChange={handleChange} className="form-control common-input" placeholder="Enter Email" />

                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Gender</label>
                                  <select name='gender' value={info.gender} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                    <option value="">Select</option>
                                    <option value="2">Female</option>
                                    <option value="1">Male</option>
                                    <option value="3">Others</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Address</label>
                                  <input type="text" name='address' value={info.address} onChange={handleChange} className="form-control common-input" placeholder="Enter Address" />
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Activity Level</label>
                                  <select name='activity_level' value={info?.activity_level} onChange={handleChange} className="form-select common-input">
                                    <option value="" selected >Select</option>
                                    <option value="Rookie">Rookie</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Advance">Advance</option>
                                    <option value="True Beast">True Beast</option>
                                  </select>
                                </div>
                              </div>
                              {/* <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                        <div className="mb-3">
                          <label className="form-label">Training Type</label>
                          <select name='training_type' value={info?.training_type} onChange={handleChange} className="form-select common-input">
                            <option value="" selected>Select</option>
                            <option value="General Training">General Training</option>
                            <option value="Personal Training">Personal Training</option>
                          </select>
                        </div>
                      </div> */}
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Goal</label>
                                  <select name='goal' value={info?.goal} onChange={handleChange} className="form-select common-input">
                                    <option value="" selected>Select</option>
                                    <option value="Gain Weight">Gain Weight</option>
                                    <option value="Lose Weight">Lose Weight</option>
                                    <option value="Get fitter">Get fitter</option>
                                    <option value="Gain more flexibility">Gain more flexibility</option>
                                    <option value="Learn the basics">Learn the basics</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                <div className="mb-3">
                                  <label className="form-label">Sale Rep</label>
                                  <select name='sales_person' value={info?.sales_person} onChange={handleChange} className="form-select common-input">
                                    <option value="" selected >Select</option>
                                    <option value="Aditi Roy">Aditi Roy</option>
                                    <option value="karan Verma">karan Verma</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="mb-3 custom-biometric">
                                  <label className="form-label">Biometric</label>
                                  <div className="Biometric-img text-center common-input">
                                    <img src={BIOMETRIC} className="img-fluid" />
                                    <p className="add-biometric pt-2">Add</p>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-12 text-end mt-3 px-3 px-sm-0">
                <button type="button" className="next-btn" onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}

export default AddCustomers;
import React, { useEffect, useState } from 'react';
import { LOGIN_LOGO } from '../Middleware/images';
import moment from 'moment';

const Invoice = (props) => {
    const { invoiceDetails, gymDetail } = props;
    const [currentDate, setCurrentDate] = useState('');

    function calculateTax(netAmount, totalTaxPercentage) {
        const taxFraction = totalTaxPercentage / 100;
        const totalTax = netAmount - netAmount / (1 + taxFraction);
        const cgst = parseFloat((totalTax / 2).toFixed(1));
        const sgst = parseFloat((totalTax / 2).toFixed(1));
        const taxableValue = parseFloat((netAmount - (cgst + sgst)).toFixed(1));
        const totalPaidAmount = taxableValue + cgst + sgst;
        const adjustment = parseFloat((netAmount - totalPaidAmount).toFixed(1));
        const finalTaxableValue = parseFloat((taxableValue + adjustment).toFixed(1));
        return {
            taxableValue: finalTaxableValue,
            cgst,
            sgst,
            totalPaidAmount: netAmount
        };
    }

    useEffect(() => {
        const date = new Date();
        const formattedDate = date.toLocaleString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        });
        setCurrentDate(formattedDate);
    }, []);

    return (
        <div className="invoice-container" style={{
            width: '800px',
            margin: 'auto',
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#fff',
            marginTop: '46px'
        }}>
            <style>
                {`
                    .header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 2px solid #000;
                        padding-bottom: 10px;
                    }

                    .email-info {
                        font-size: 12px;
                        line-height: 1.5;
                    }

                    .email-info p {
                        margin: 0;
                    }

                    .email-info a {
                        color: #000;
                        text-decoration: none;
                    }

                    .logo img {
                        width: 230px;
                        height: auto;
                    }

                    .gym-details {
                        text-align: right;
                        font-size: 12px;
                    }

                    h1 {
                        text-align: center;
                        font-size: 24px;
                        font-weight: bold;
                        margin-top: 20px;
                    }

                    .bill-to-details {
                        display: flex;
                        justify-content: space-between;
                        border-top: 2px solid #000;
                        padding-top: 20px;
                        margin-top: 20px;
                        font-size: 12px;
                    }

                    .bill-to h3, .details h3 {
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    .bill-to p, .details p {
                        margin: 4px 0;
                    }

                    .invoice-table {
                        width: 100%;
                        border-collapse: collapse;
                        margin-top: 20px;
                        font-size: 12px;
                    }

                    .invoice-table, .invoice-table th, .invoice-table td {
                        border: 1px solid #000;
                    }

                    .invoice-table th, .invoice-table td {
                        padding: 8px;
                        text-align: left;
                    }

                    .invoice-table th {
                        background-color: #f2f2f2;
                        font-weight: bold;
                    }

                    .summary {
                        margin-top: 20px;
                        font-size: 12px;
                        font-weight: bold;
                        text-align: right;
                    }

                    .summary p {
                        margin: 4px 0;
                    }

                    hr {
                        border-top: 2px solid #000;
                        margin: 20px 0;
                        opacity : unset
                    }

                  .terms-conditions h3 {
                   font-size: 14px;
                   font-weight: bold;
                    margin-bottom: 10px;
                    }

                   .terms-conditions ul {
                    padding-left: 20px;
                    font-size: 12px;
                    list-style-type: disc; /* Ensures bullet points are shown */
                     }

                    .terms-conditions ul li {
                     margin-bottom: 8px;
                     }


                    .signature {
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        margin-top: 50px;
                    }

                    .signature p {
                        margin: 0;
                    }
                `}
            </style>
            <div className="header">
                <div className="email-info">
                    <p>
                        <strong>From:</strong>

                        <a href="info@fitx.pro" style={{ color: '#0d6efd', marginLeft: 5 }} >{gymDetail.email}</a>
                    </p>
                    <p><strong>Subject:</strong><strong style={{ fontWeight: "600" }}> Receipt </strong></p>
                    <p><strong>Date:</strong> {currentDate}</p>
                    {
                        invoiceDetails?.user?.email &&
                        <p>
                            <strong>To:</strong>
                            <a style={{ color: '#0d6efd' }} href={`${invoiceDetails?.user?.email}`}>{invoiceDetails?.user?.email}</a>
                        </p>
                    }
                </div>
                <div className="logo">
                    <img
                        src={LOGIN_LOGO}
                        alt="Gym Logo"
                    />
                </div>
                <div className="gym-details">
                    <p>Fitx Pro Fitness</p>
                    <p>SCO-62-63-64, level 2nd and 3rd,</p>
                    <p>Phase 10, Sector 64, Mohali, Punjab (India)</p>
                    <p>9919600015</p>
                    <strong>GST No : </strong>
                    {/* <p>{gymDetail?.branch_details?.address_line_1}</p>
                        <p>{gymDetail?.branch_details?.address_line_2},{gymDetail?.branch_details?.city}, {gymDetail?.branch_details?.state}, {gymDetail?.branch_details?.pincode}</p>
                        <p>{gymDetail.phone_no}</p>
                        <strong>GST No : </strong> */}
                </div>
            </div>
            <h1 style={{ fontFamily: 'Arial Black, sans-serif', textAlign: 'center' }}>Tax Invoice</h1>
            <div className="bill-to-details">
                <div className="bill-to">
                    <h3 style={{ fontFamily: 'Arial Black, sans-serif' }}>Bill To</h3>
                    <p>{invoiceDetails?.user?.name}</p>
                    {
                        invoiceDetails?.user?.address_1 &&
                        <p>{invoiceDetails?.user?.address_1}</p>
                    }
                    <p>Mobile No.: {invoiceDetails?.user?.mobile_number}</p>
                    {
                        invoiceDetails?.user?.email &&
                        <p>
                            Email:
                            <a style={{ color: '#0d6efd' }} href={`${invoiceDetails?.user?.email}`}>{invoiceDetails?.user?.email}</a>
                        </p>
                    }
                </div>
                <div className="details">
                    <h3 style={{ fontFamily: 'Arial Black, sans-serif' }}>Details</h3>
                    <p>Bill Date: {moment(invoiceDetails.created_at).format('Do MMM, YYYY')}</p>
                    <p>Bill No: {invoiceDetails.bill_number}</p>
                </div>
            </div>

            <table className="invoice-table">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Payment Mode</th>
                        <th>Base Amount</th>
                        <th>Discount</th>
                        <th>Net Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {invoiceDetails.plan_type === '1' ? 'General Training' : 'Personal Training'} {' '}
                            {invoiceDetails?.membership_details?.month}
                            {invoiceDetails?.membership_details?.month === '1' ? ' Month' : ' Months'}{' '}
                            ({moment(invoiceDetails?.membership_details?.start_date).format('Do MMM, YYYY')} - {moment(invoiceDetails.membership_details?.end_date).format('Do MMM, YYYY')})
                        </td>
                        <td style={{ textAlign: !invoiceDetails.mode_of_payment ? "center" : "" }}>
                            {invoiceDetails.mode_of_payment ? invoiceDetails.mode_of_payment : "-"}
                        </td>
                        <td>₹ {invoiceDetails.base_amount}</td>
                        <td>₹ {invoiceDetails.discount ? invoiceDetails.discount : 0}</td>
                        <td>₹ {invoiceDetails.net_amount}</td>
                    </tr>
                </tbody>
            </table>

            <div className="summary">
                <p><strong>Total Amount:</strong> ₹ {invoiceDetails.net_amount}</p>
                {/* <p><strong>Paid Amount:</strong> ₹ {invoiceDetails.paid_amount}</p> */}
                <p><strong>Amount Due:</strong> ₹ {invoiceDetails.balance_amount}</p>
            </div>
            <hr />

            <div className="summary">
                <p><strong> Amount:</strong> ₹{calculateTax(invoiceDetails.paid_amount, 18).taxableValue} </p>
                <p><strong>CGST:</strong> ₹ {calculateTax(invoiceDetails.paid_amount, 18).cgst}</p>
                <p><strong>SGST:</strong> ₹ {calculateTax(invoiceDetails.paid_amount, 18).sgst}</p>
                <p><strong>Total Paid Amount:</strong> ₹ {calculateTax(invoiceDetails.paid_amount, 18).totalPaidAmount}</p>
            </div>
            <hr />

            <div className="terms-conditions">
                <h3 style={{ fontFamily: 'Arial Black, sans-serif' }}>Terms & Conditions:</h3>
                <ul>
                    <li>Management reserves the right to admission.</li>
                    <li>Membership is neither refundable nor adjustable in any circumstances.</li>
                    <li>Payment done for a product/package/service cannot be adjusted against another product/package/service.</li>
                    <li>Management shall not be responsible for any kind of loss of valuable items of members and their guests.</li>
                    <li>Do carry Hand towels & Deodorants to maintain hygiene.</li>

                    <li> Do meet the Floor manager for warm-up & Stretching before & after a workout.
                    </li>
                    <li>Meet Fitness manager whenever there is a lack of motivation, queries related to workout or Diet.</li>
                    <li>Register on Broadcast group for updates on outdoor activities & Boot camps.</li>
                    <p>
                        Declaration: I understand that strength, flexibility, and aerobic exercise, including the use of equipment, is a potentially hazardous activity also understand fitness activities involve risk of injury and that I am voluntarily participating in these activities and using of equipment.
                    </p>
                </ul>
            </div>

            <hr />
            <div className="signature">
                <p><strong>Member ____________________</strong></p>
                <p style={{ marginTop: '30px' }}>(I have read the Terms / Conditions / Guidelines and agree to abide by them)</p>
                <p style={{ float: 'right' }}>Authorized Signatory</p>
            </div>
        </div>
    );
};

export default Invoice;

import React, { useEffect, useRef, useState } from 'react';
import { BILLING, DELETE_ICON, DOWNLOAD, PRINT } from '../Middleware/images';
import moment from "moment";
import Pagination from './pagination';
import Invoice from './invoice';
import html2pdf from 'html2pdf.js';

function Bills(props) {
    const invoiceRef = useRef();
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const printIframeRef = useRef();
    const [openPrint, setOpenPint] = useState(false)

    const handleDownload = (item) => {
        const element = invoiceRef.current;
        setInvoiceDetails(item);
        if (element) {
            setTimeout(() => {
                html2pdf()
                    .from(element)
                    .set({
                        margin: 1,
                        filename: `${item.user.name}_invoice.pdf`,
                        html2canvas: { scale: 2, logging: true, useCORS: true },
                        jsPDF: { format: 'a4', orientation: 'portrait' }
                    })
                    .save()
                    .catch(err => console.error("PDF generation error: ", err));
            }, 500);
        } else {
            console.error("Invoice reference is not set.");
        }
    };

    const handlePrint = (item) => {
        setOpenPint(true)
        setInvoiceDetails(item);
    };


    useEffect(() => {
        if (openPrint) {
            setOpenPint(false)
            const iframe = printIframeRef.current;
            const content = invoiceRef.current.innerHTML;
            const doc = iframe.contentWindow.document;
            doc.open();
            doc.write('<html><head><title>Print Invoice</title>');
            doc.write('<link rel="stylesheet" href="your-stylesheet.css">');
            doc.write('</head><body>');
            doc.write(content);
            doc.write('</body></html>');
            doc.close();
            iframe.onload = function () {
                iframe.contentWindow.print();
            };
        }
    }, [openPrint])

    return (
        <>
            <div className="row h-100 align-items-center">
                <div className="col-12">
                    {
                        props?.bill?.length === 0 ? (
                            <div className="no-data-content text-center">
                                <img src={BILLING} className="img-fluid" />
                            </div>
                        ) : (
                            <div className="table-responsive leads-table-responsive member-table-top">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="leads-table-head">Purchase  date</th>
                                            <th scope="col" className="leads-table-head">Bill No</th>
                                            <th scope="col" className="leads-table-head">Start date</th>
                                            <th scope="col" className="leads-table-head">End date</th>
                                            <th scope="col" className="leads-table-head">Base amount</th>
                                            <th scope="col" className="leads-table-head">Discount</th>
                                            <th scope="col" className="leads-table-head">Net amount</th>
                                            <th scope="col" className="leads-table-head">Balance Amount</th>
                                            <th scope="col" className="leads-table-head">Payment Mode</th>
                                            <th scope="col" className="leads-table-head">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props?.bill?.map((item) => {
                                                const discountPercentage = ((item.base_amount - item.net_amount) / item.base_amount) * 100;
                                                const roundedDiscountPercentage = discountPercentage % 1 === 0 ? discountPercentage.toFixed(0) : discountPercentage.toFixed(2);
                                                return (
                                                    <tr key={item?.id}>
                                                        <td className="leads-table-data text-start">{moment(item.created_at).format('Do MMM, YYYY')}</td>
                                                        <td className="leads-table-data">{item?.bill_number}</td>
                                                        <td className="leads-table-data">{item?.membership_details?.start_date ? moment(item?.membership_details?.start_date).format('Do MMM, YYYY') : "-"}</td>
                                                        <td className="leads-table-data">{item?.membership_details?.end_date ? moment(item?.membership_details?.end_date).format('Do MMM, YYYY') : "-"}</td>
                                                        <td className="leads-table-data">{`₹${item?.base_amount}`}</td>
                                                        <td className="leads-table-data ">
                                                            {discountPercentage === 0 ? "-" : `${roundedDiscountPercentage}%`}
                                                        </td>
                                                        <td className="leads-table-data">{`₹${item?.net_amount}`}</td>
                                                        <td className="leads-table-data">{item?.balance_amount === "0" ? "₹0" : `₹${item?.balance_amount}`}</td>
                                                        <td className="leads-table-data">{item?.mode_of_payment}</td>
                                                        <td>
                                                            <div className="action-btns d-flex justify-content-center text-start align-items-center gap-3">
                                                                <img
                                                                    src={DELETE_ICON}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    onClick={() => props.handleBillOpen(item.id)}
                                                                />
                                                                {
                                                                    item.status !== 3 && (
                                                                        <img
                                                                            src={PRINT}
                                                                            style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                            onClick={() => handlePrint(item)}
                                                                            alt=""
                                                                            className="img-fluid"
                                                                        />
                                                                    )
                                                                }
                                                                <img
                                                                    src={DOWNLOAD}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                                                    onClick={() => handleDownload(item)}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )

                    }
                    {
                        props?.bill?.length > 0 && <>
                            <div className="row pt-3">
                                <div className="col-6">
                                    <a href=""><span className="page-entry">
                                        {
                                            props.total === 1 ? <>
                                                Showing {props.total1} entry
                                            </> :
                                                <>
                                                    Showing {props.startIndex} to {props.endIndex1} of {props.total1} entries
                                                </>
                                        }
                                    </span>
                                    </a>
                                </div>
                                <div className="col-6">
                                    <nav aria-label="Page">
                                        <ul className="pagination d-flex justify-content-end">
                                            <Pagination currentPage={props.currentPage} totalPages={props.totalPages1} onPageChange={props.handlePageChange} />
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </>
                    }
                    {
                        invoiceDetails &&
                        <div style={{ display: 'none' }}>
                            <div ref={invoiceRef}>
                                <Invoice
                                    invoiceDetails={invoiceDetails}
                                    gymDetail={props.gymDetail}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            <iframe
                ref={printIframeRef}
                style={{ display: 'none' }}
                title="Print Invoice"
            />
        </>
    );
}

export default Bills;
